.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*traing Css */

.offer-container h2 {
  font-size: 140px;
  display: block;
  color: #2c234d;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  -webkit-text-stroke: 1px #f6f7f8;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 40px;
  }
}
.offer-container .sub-info h5 > a {
  font-size: 30px;

  font-weight: 500;
  margin-bottom: 14px;
  display: block;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  text-transform: uppercase;
  color: #fff;
}

.offer-container .sub-info h5 > p{
  color: #fff;
    font-size: 16px;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    margin: 0;
}